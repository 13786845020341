import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "../../components/Header/Header.jsx";
import ContactSection from "./Sections/ContactSection.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import HeaderLinks from "../../components/Header/HeaderLinks.jsx";
import Parallax from "../../components/Parallax/Parallax.jsx";
import styles from "../../assets/jss/material-kit-react/views/contactPage.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

import {graphql, StaticQuery} from "gatsby";
import CookieSnackbar from "../Components/CookieSnackbar";

const dashboardRoutes = [];

const pageQuery = graphql`
  {
    allGraphCmsImpressum {
      nodes {
        header {
          localFile {
            localURL
          }
        }       
      }
    }
  }
`;


class ContactPage extends React.Component {

    render() {
        const {classes, ...rest} = this.props;
        return <StaticQuery query={pageQuery}>{({allGraphCmsImpressum: { nodes }}) => {
            const{header} = nodes[0]; // select first, whatever this is!

            return <div>
                <Header
                    color="dark"
                    routes={dashboardRoutes}
                    brand="Gasthaus Häring"
                    brandLink="/"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    {...rest}
                />
                <Parallax small filter image={header.localFile.localURL}/>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <ContactSection/>
                </div>
                <Footer/>
                <CookieSnackbar/>
            </div>}}
        </StaticQuery>;
    }


}
export default withStyles(styles)(ContactPage);

//<script id="mapsScript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCfdbU5S2GZH81V51bTSIQr0TCn3svbpz8&callback=initMap" async="true"/>
